import Container from '../Container/Container';
import styles from './SectionContainer.module.scss'

type IBigSectionContainer = {
	children: any,
	wr?: boolean,
	fixHeight?: boolean,
	fixSectionHeight?: boolean
}

const SectionContainer = ({ children, wr, fixHeight, fixSectionHeight }: IBigSectionContainer) => {
	return (
		<section className={`${styles.section_container} ${fixSectionHeight ? styles.fixHeight2 : ''}`}>
			<Container>
				<div
					className={`${styles.wrapper} ${wr ? styles.wr : ''} ${fixHeight ? styles.fixHeight : ''}`}
				>
					{children}
				</div>
			</Container>
		</section>
	)
}

export default SectionContainer;