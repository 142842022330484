import { Container, Row } from "../../ui/grid";
import styles from './Footer.module.scss'
import MailSvg from "../../../svg/MailSvg";

type FooterType = {
  email: any
}

const Footer = ({email}: FooterType) => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.contacts}>
          <div className={styles.contact_title}>Написать разработчику</div>
          <div className={styles.contact_mail}>
            <Row center>
              <MailSvg />
              <a href={`mailto:${email}`}>{email}</a>
            </Row>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer;
