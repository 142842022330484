export default function RussianFlagSvg() {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path fill="#fff" d="M0 0h24v8H0z"/>
        <path fill="#0688FF" d="M0 8h24v8H0z"/>
        <path fill="#E02A2A" d="M0 16h24v8H0z"/>
      </g>
      <rect x=".25" y=".25" width="23.5" height="23.5" rx="2.75" stroke="#0E3D57" strokeOpacity=".3"
            strokeWidth=".5"/>
      <defs>
        <clipPath id="a">
          <rect width="24" height="24" rx="3" fill="#fff"/>
        </clipPath>
      </defs>
    </svg>
  );
}
