export default function ForeinSvg() {
  return (
    <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 21c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10zM1 11h20" stroke="#0E3D57"
            strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M3.93 4.071A9.969 9.969 0 0 0 11 7a9.969 9.969 0 0 0 7.072-2.929M18.072 17.929A9.969 9.969 0 0 0 11 15a9.969 9.969 0 0 0-7.071 2.929"
        stroke="#0E3D57" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11 1S7 4.5 7 11s4 10 4 10M11 1s4 3.5 4 10-4 10-4 10" stroke="#0E3D57" strokeWidth="2"/>
    </svg>
  );
}
