export default function PAddressSvg() {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity=".4" x="1" y="1" width="38" height="38" rx="9" stroke="#fff" strokeWidth="2"/>
      <path
        d="M12.984 8.453V31h4.72v-7.11h4.218c4.75 0 8.047-3.093 8.047-7.718 0-4.64-3.188-7.719-7.813-7.719h-9.172zm4.72 3.735h3.202c2.703 0 4.266 1.437 4.266 4 0 2.578-1.578 4.015-4.281 4.015h-3.188v-8.015z"
        fill="#fff"/>
    </svg>
  );
}
