import { Navigate, useParams, useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { getPaymentInfo } from "../../../api/query/Payment";

const PaymentResultPlugPage = () => {
	const [searchParams] = useSearchParams();
	const paymentID = searchParams.get('paymentID');
	const type = searchParams.get('type');
	const { zone } = useParams();

	const [zoneState, setZoneState] = useState<string>();

	useEffect(() => {
		const callData = () => {
			getPaymentInfo(paymentID).then((data) => {
				setZoneState(data.result.zoneNumber);
			});
		};
		const interval = setInterval(callData, 500);
		callData();
		return () => {
			clearInterval(interval);
		};
	}, [paymentID]);

	return type === 'qr' ? <Navigate replace to={`/${zone}/result/${paymentID}?type=qr`} /> : (<Navigate replace to={`/${zoneState}/result/${paymentID}`} />);
}

export default PaymentResultPlugPage
