export default function PhonePaymentSvg() {
  return (
    <svg width="56" height="56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M26 4H15.645A3.645 3.645 0 0 0 12 7.645v38.71A3.645 3.645 0 0 0 15.645 50H26V4z" fill="#707789"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M26 4h10.366A3.634 3.634 0 0 1 40 7.634v38.732A3.634 3.634 0 0 1 36.366 50H26V4z" fill="#555A66"/>
      <path d="M15 8.991C15 7.891 15.892 7 16.991 7H26v40h-9.009c-1.1 0-1.991-.892-1.991-1.991V8.99z" fill="#fff"/>
      <path d="M37 8.992C37 7.892 36.108 7 35.008 7H26v40h9.008c1.1 0 1.992-.892 1.992-1.992V8.992z" fill="#E9EDF5"/>
      <path d="M21 7h5v2h-3.846L21 7z" fill="#707789"/>
      <path d="M31 7h-5v2h3.846L31 7z" fill="#555A66"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M26 12a8 8 0 1 0 0 16V12z" fill="#20F15B"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M26 12a8 8 0 1 1 0 16V12z" fill="#2BC556"/>
      <path
        d="m21.466 16.58 1.293-1.291a.983.983 0 0 1 1.392 0l1.39 1.39a.98.98 0 0 1 0 1.393l-1.017 1.017a4.78 4.78 0 0 0 .977 1.409c.407.409.884.74 1.41.98l1.016-1.017a.98.98 0 0 1 1.392 0l1.392 1.39a.98.98 0 0 1 .289.696.98.98 0 0 1-.289.697l-1.29 1.29a1.595 1.595 0 0 1-1.382.444c-1.636-.27-3.259-1.14-4.568-2.448-1.308-1.307-2.177-2.928-2.45-4.569a1.597 1.597 0 0 1 .445-1.38z"
        fill="#fff"/>
      <rect width="38" height="6" rx="1" transform="scale(-1 1) rotate(-18.878 89.22 156.689)" fill="#393939"/>
      <path
        d="m37.381 34.383-8.516-2.912 3.736 7.619L41.117 42l-3.736-7.618zM21.296 28.883l-8.515-2.912 3.735 7.618 8.516 2.912-3.736-7.618z"
        fill="#E9AF1A"/>
      <path d="M51 40a5 5 0 0 0-10 0v10h10V40zM53 52a2 2 0 0 0-2-2H41a2 2 0 0 0-2 2v2h14v-2z" fill="#DF4A1B"/>
      <circle r="2" transform="matrix(-1 0 0 1 46 40)" fill="#fff"/>
      <rect width="52" height="2" rx="1" transform="matrix(-1 0 0 1 54 54)" fill="#393939"/>
    </svg>
  );
}
