export default function SubscriptionSvg() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.57129" y="11.3767" width="18" height="30.3896" fill="white"/>
      <rect x="5.14307" y="29.1428" width="12.8571" height="3.42857" fill="#636978"/>
      <rect x="5.14307" y="35.1428" width="12.8571" height="3.42857" fill="#AFB4C8"/>
      <circle cx="11.3398" cy="20.6999" r="5.58" fill="#7E8596"/>
      <rect x="27.4287" y="11.3767" width="18" height="30.3896" fill="white"/>
      <rect x="30.2964" y="29.1428" width="12.6078" height="3.37708" fill="#636978"/>
      <rect x="30" y="35.1428" width="12.8571" height="3.42857" fill="#AFB4C8"/>
      <circle cx="36.54" cy="20.6999" r="5.58" fill="#7E8596"/>
      <rect x="14.5713" y="6" width="18.8571" height="30.8571" fill="white"/>
      <rect opacity="0.5" x="24" y="6" width="9.42857" height="30.8571" fill="#C7D2E3"/>
      <rect x="18" y="22.2856" width="6" height="3.42857" fill="#FE6A16"/>
      <rect x="18" y="28.2856" width="6" height="3.42857" fill="#AFB4C8"/>
      <rect x="24" y="22.2856" width="6" height="3.42857" fill="#F24500"/>
      <rect x="24" y="28.2856" width="6" height="3.42857" fill="#707789"/>
      <circle cx="24" cy="14.5715" r="6" fill="#FFCE00"/>
    </svg>
  );
}
