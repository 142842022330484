import { NavLink, useParams } from "react-router-dom";
import { Container, Row } from "../../ui/grid";
import styles from './Header.module.scss'
import LogoSvg from "../../../svg/LogoSvg";
import OperatorSvg from "../../../svg/OperatorSvg";
import PAddressSvg from "../../../svg/PAddressSvg";

type HeaderType = {
  address: any,
  tel: any,
}

const Header = ({ address, tel }: HeaderType) => {
  const { zone } = useParams();

  return (
    <header className={styles.header}>
      <Container>
        <Row spaceBetween>
          <div className={styles.logo}>
            <NavLink to={`/${zone}/`}>
              <LogoSvg />
            </NavLink>
          </div>
          { tel ? (
            <a href={`tel: ${tel}`} className={styles.operator_btn}>
              <Row center>
                <OperatorSvg />
                <div className={styles.operator_btn__text}>оператор</div>
              </Row>
            </a>
          ) : null }
        </Row>

        <div className={styles.parking_address}>
          <div className={styles.parking_address__p}>
            <PAddressSvg />
          </div>
          <div className={styles.parking_address__items}>
            <div className={styles.parking_address__title}>текущая парковка</div>
            <div className={styles.parking_address__text}>{address}</div>
          </div>
        </div>
      </Container>
    </header>
  )
}

export default Header;
