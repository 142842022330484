import {getParkingSessionPhoto} from 'api/query/ParkingSession'
import {useEffect, useState} from 'react'
import styles from './AutoItem.module.scss'
import {PageLoading} from "../../../svg/PageLoading";

type IGrzAutoItem = {
  commonClass: string,
  sessionId: any,
  dateFull: any,
  isSelected: boolean,
  changed: any,
  zone: string | undefined,
}

const AutoItem = ({commonClass, sessionId, dateFull, isSelected, changed, zone}: IGrzAutoItem) => {
  const [imgResut, setImgResut] = useState('')
  const date = (dateFull)?.toString().split('T')[0]
  const time = (dateFull)?.toString().split('T')[1]

  useEffect(() => {
    getParkingSessionPhoto(zone, sessionId)
      .then((data: any) => {
        setImgResut(data.result)
      })
  }, [zone, sessionId])

  return (
    <button
      onClick={changed}
      className={` ${commonClass} ${styles.item} ${isSelected ? styles.active : ''} `}
    >
      <div className={` ${commonClass} ${isSelected ? styles.active_click : ''}`}/>
      {imgResut ? (
        <>
          <img className={commonClass} src={`data:image/png;base64, ${imgResut ? imgResut : ''}`} alt=""/>
          <div className={`${commonClass} ${styles.item_info}`}>
            <span className={commonClass}>{date}</span>
            <span className={commonClass}>{time}</span>
          </div>
        </>
      ) : <PageLoading/>}
    </button>
  );
}

export default AutoItem;
