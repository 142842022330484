import Title from "../ui/title/Title";
import styles from './ErrorPage.module.scss'
import {NavLink, useParams} from "react-router-dom";
import {SectionContainer} from "../ui/grid";
import {useState} from "react";
import {InfoType} from "api/query/Info";
import Layout from '../layout'
import {PaymentType} from "../../api/query/ParkingSession";
import PhoneSvg from "../../svg/PhoneSvg";
import NoCameraSvg from "../../svg/NoCameraSvg";

const ErrorPage = ({paymentType}: { paymentType: PaymentType }) => {
  const {zone} = useParams();
  const [infoState, setInfoState] = useState<InfoType>();

  let title: string = '';
  let subtitle: string = '';
  let textBtn: string = '';
  let urlBtn: string = '';
  let textReturnBtn: string = 'Назад';
  let urlReturnBtn: string = '';
  if (paymentType === PaymentType.grz) {
    title = infoState && infoState.result.tel ? 'Не удалось найти автомобиль, свяжитесь с оператором' : 'Не удалось найти автомобиль';
    subtitle = 'Возможно вы заезжали по звонку, попробуйте ввести номер телефона';
    textBtn = 'ввести номер телефона';
    urlBtn = `/${zone}/phone`;
    urlReturnBtn = `/${zone}/grz`;
  } else if (paymentType === PaymentType.phone) {
    title = infoState && infoState.result.tel ? 'Не удалось найти телефон, свяжитесь с оператором' : 'Не удалось найти телефон';
    textBtn = 'ввести номер автомобиля';
    urlBtn = `/${zone}/grz`;
    urlReturnBtn = `/${zone}/phone`;
  }

  return (
    <Layout infoListener={setInfoState}>
      <SectionContainer wr fixHeight>
        <div className={styles.container}>
          <div className={styles.containerTop}>
            <div className={styles.img}>
              <NoCameraSvg/>
            </div>
            <Title center text={title}/>
            <div className={styles.subtitle}>{subtitle}</div>
          </div>
          <div className={styles.btns}>
            <NavLink to={urlBtn} className={styles.btn}>
              {paymentType === PaymentType.grz ? <PhoneSvg/> : null}
              <span>{textBtn}</span>
            </NavLink>
            {infoState && infoState.result.tel ? (
              <a href={`tel: ${infoState?.result.tel}`} className={styles.btn}>
                <img src='' alt=""/>
                <span>связь с оператором</span>
              </a>
            ) : null}
            <NavLink to={urlReturnBtn} className={`${styles.btn} ${styles.btn__main_screen} `}>
              {textReturnBtn}
            </NavLink>
          </div>
        </div>
      </SectionContainer>
    </Layout>
  )
}

export default ErrorPage;
