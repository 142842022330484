import styles from './PersonalData.module.scss'
import Title from '../ui/title/Title';
import { Container } from '../ui/grid';
import { useState } from 'react';

type IPersonalData = {
  visible: boolean
}

const PersonalData = ({ visible }: IPersonalData) => {
  const [isClick, setIsClick] = useState(false)

  return (
    <div className={styles.container}>
      <Container>
        <div className={`${styles.wrapper}  ${(visible && !isClick) || (!visible && isClick) ? '' : styles.hidden}`}>
          <div className={styles.scrollbar_container}>
            <div className={styles.btn}>
              <button onClick={() => { setIsClick(!isClick) }}>
                Назад
              </button>
            </div>

            <Title text="Условия пользовательского соглашения" />
            <div className={styles.text}>
              Как было показано выше, сублимация монотонно вызывает архетип. Диалогичность, в первом приближении, продолжает фактографический символический метафоризм, подобный исследовательский подход к проблемам художественной типологии можно обнаружить у К.Фосслера. Синтаксис искусства монотонно заканчивает мимезис. Параллельность стилевого развития дает
              Как было показано выше, сублимация монотонно вызывает архетип. Диалогичность, в первом приближении, продолжает фактографический символический метафоризм, подобный исследовательский подход к проблемам художественной типологии можно обнаружить у К.Фосслера. Синтаксис искусства монотонно заканчивает мимезис. Параллельность стилевого развития дает

              <br />
              <br />
              <br />
              <br />


            </div>
          </div>
          <div className={styles.text_attenuation}></div>

        </div>
      </Container>
    </div>
  )
}

export default PersonalData;