export default function BuySubscriberSvg() {
  return (
    <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#fff" d="M2.571 11.377h18v30.39h-18z"/>
      <path fill="#636978" d="M5.143 29.143H18v3.429H5.143z"/>
      <path fill="#AFB4C8" d="M5.143 35.143H18v3.429H5.143z"/>
      <circle cx="11.34" cy="20.7" r="5.58" fill="#7E8596"/>
      <path fill="#fff" d="M27.429 11.377h18v30.39h-18z"/>
      <path fill="#636978" d="M30.296 29.143h12.608v3.377H30.296z"/>
      <path fill="#AFB4C8" d="M30 35.143h12.857v3.429H30z"/>
      <circle cx="36.54" cy="20.7" r="5.58" fill="#7E8596"/>
      <path fill="#fff" d="M14.571 6h18.857v30.857H14.571z"/>
      <path opacity=".5" fill="#C7D2E3" d="M24 6h9.429v30.857H24z"/>
      <path fill="#FE6A16" d="M18 22.286h6v3.429h-6z"/>
      <path fill="#AFB4C8" d="M18 28.286h6v3.429h-6z"/>
      <path fill="#F24500" d="M24 22.286h6v3.429h-6z"/>
      <path fill="#707789" d="M24 28.286h6v3.429h-6z"/>
      <circle cx="24" cy="14.572" r="6" fill="#FFCE00"/>
    </svg>
  );
}
