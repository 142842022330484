export default function PaymentAutoSvg() {
  return (
    <svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity=".5" d="m16 22-5 13h29l-4-13H16z" fill="#0D9E8F"/>
      <circle cx="32.5" cy="34.5" r="4.5" stroke="#0B766C" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M15.44 21a1 1 0 0 0-.954.7l-3.67 11.701-.816-.815V31a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h3.586l1.036 1.035A2 2 0 0 0 8 37v11a2 2 0 0 0 2 2h32a2 2 0 0 0 2-2V37c0-.906-.602-1.67-1.427-1.917L43.657 34h3.586a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1.586l-1 1-3.73-11.886a1 1 0 0 0-.953-.7H15.44zm.472 2.727a1 1 0 0 1 .962-.727h18.252a1 1 0 0 1 .963.727l2.55 9A1 1 0 0 1 37.677 34H14.323a1 1 0 0 1-.962-1.273l2.55-9z"
            fill="#DF4A1B"/>
      <circle cx="15" cy="42" r="3" fill="#FFCC19"/>
      <circle cx="37" cy="42" r="3" fill="#FFCC19"/>
      <rect opacity=".5" x="20" y="40" width="12" height="2" rx="1" fill="#FFCC19"/>
      <rect opacity=".5" x="22" y="43" width="8" height="2" rx="1" fill="#FFCC19"/>
      <rect x="20" y="48" width="12" height="4" rx="1" fill="#fff"/>
      <path d="M11 50h6v4a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-4zM35 50h6v4a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-4z"
            fill="#393939"/>
      <path d="M51 27a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v27h-7V27z" fill="#C5E2EC"/>
      <rect x="49" y="54" width="11" height="2" rx="1" fill="#0B766C"/>
      <rect x="46.961" y="16.492" width="6" height="4.1" rx="1" transform="rotate(-15 46.96 16.492)" fill="#0B766C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M52 17.968h-2V22a1 1 0 0 0 1 1h7v-2h-6v-3.032z" fill="#0B766C"/>
      <path
        d="M36.65 14.301a.5.5 0 0 1 .214-.846l19.392-5.196a1 1 0 0 1 1.225.707l1.615 6.026a1 1 0 0 1-.707 1.225l-14.82 3.97a1 1 0 0 1-.945-.238L36.65 14.3z"
        fill="#C5E2EC"/>
      <circle cx="55" cy="13" r="1" fill="#A32020" fillOpacity=".9"/>
    </svg>
  );
}
