export default function PaymentCheckSvg() {
  return (
    <svg width="400" height="40" viewBox="0 0 400 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="400" height="22.857084" rx="11.428569" fill="#2a5a74" x="0" y="1.4916611"/>
      <rect x="7.8575187" y="10.062901" width="383.57153" height="5.7142782" rx="2.8571391" fill="#0e3d57"/>
      <path d="M 23.050847,10.169492 H 376.94915 V 40 H 23.050847 Z" fill="#ddeafd"/>
      <path d="M 22.857491,10.062902 H 377.14305 V 39.34861 H 22.857491 Z"/>
    </svg>
  );
}
