import PersonalData from "../../personal-data/PersonalData";
import {Container, Row} from "../../ui/grid";
import Title from "../../ui/title/Title";
import {GrzHelper} from "helper/GrzHelper";
import {useEffect, useRef, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import styles from './SubscriptionInputs.module.scss'
import Layout from '../../layout'
import Phone2Svg from "../../../svg/Phone2Svg";
import CheckSvg from "../../../svg/CheckSvg";
import {PhoneHelper} from "../../../helper/PhoneHelper";
import RussianFlagSvg from "../../../svg/RussianFlagSvg";
import ForeinSvg from "../../../svg/ForeinSvg";
import Triangle2Svg from "../../../svg/Triangle2Svg";
import SubscriptionSvg from "../../../svg/SubscriptionSvg";

const SubscriptionInputsPage = () => {
  const {zone, subscriptionCode} = useParams();

  const [openCountryState, setOpenCountryState] = useState(false);
  const [rusFormatState, setRusFormatState] = useState(true);
  const [checkedState, setCheckedState] = useState(false);
  const [openPersonalDataState, setOpenPersonalDataState] = useState(false);
  const [validRusState, setValidRusState] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [grzState, setGrzState] = useState('');
  const [grzStateWithoutSpace, setGrzStateWithoutSpace] = useState('');
  const [phoneValue, setPhoneValue] = useState('')
  const [errorPhoneValue, setErrorPhoneValue] = useState(true)
  const inputPhone = useRef<any>()
  let phoneValueW7 = phoneValue.match(/(\+7)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
  let phoneValueW7Space = phoneValueW7 ? phoneValueW7[0] : ''
  let phoneValueW8 = phoneValue.match(/(8)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
  let phoneValueW8Space = phoneValueW8 ? phoneValueW8[0] : ''

  const handleChangeGrz2 = (e: any) => {
    const grzValue = e.target.value;
    if (rusFormatState) {
      const grzWithoutSpaces = GrzHelper.clearRus(grzValue);
      const grzArr = grzWithoutSpaces.match(/^([\s\S])([\s\S]{0,3})([\s\S]{0,2})([\s\S]{0,3})/);
      const grzFormatted = (Array.isArray(grzArr) ? grzArr : []).filter((v, i) => (i > 0 && v)).join(' ');
      setGrzState(grzFormatted);
    } else {
      setGrzState(grzValue);
    }
  }

  useEffect(() => {
    setGrzStateWithoutSpace(grzState.replace(/\s/g, ''));
    const validRus = GrzHelper.testRus(grzState);
    setErrorState(!openCountryState && !!grzState && grzState.length >= 11 && !validRus);
    setValidRusState(validRus);
  }, [grzState, openCountryState]);

  const grzChangerMode = () => {
    setRusFormatState((s) => (!s));
    setOpenCountryState(!openCountryState);
    setGrzState('');
    setErrorState(false)
  }

  const handleChangeValue = () => {
    const phone = PhoneHelper.handleChangeValue(inputPhone.current.value);
    setPhoneValue(phone.clear);
    inputPhone.current.value = phone.formatted;
  }

  useEffect(() => {
    /* if (checkedState) {
      setErrorPhoneValue(phoneValueW8Space.length === 11 || phoneValueW7Space.length === 12)
    } */
    setErrorPhoneValue(phoneValueW8Space.length === 11 || phoneValueW7Space.length === 12)
    handleChangeValue();
  }, [phoneValue, checkedState, phoneValueW8Space.length, phoneValueW7Space.length]);


  const canNext = () => {
    return (!openCountryState && validRusState && checkedState && !errorState && (phoneValueW8Space.length === 11 || phoneValueW7Space.length === 12)) || (openCountryState && checkedState && (phoneValueW8Space.length === 11 || phoneValueW7Space.length === 12));
  }

  const searchParams = new URLSearchParams({
    grz: grzStateWithoutSpace ?? '',
    phone: phoneValue ?? '',
  });

  return (
    <Layout>
      <section className={styles.section}>
        <Container>
          <div className={styles.img}>
            <SubscriptionSvg/>
          </div>
          <div className={styles.form}>
            <Title text="Введите номер автомобиля и телефон"/>
            <div className={styles.grz_input}>
              <Row center>
                <button onClick={() => {
                  setOpenCountryState(!openCountryState)
                }} className={styles.grz_input_btn}>
                  <Row center>
                    {rusFormatState ? <RussianFlagSvg/> : <ForeinSvg/>}
                    <Triangle2Svg/>
                  </Row>
                </button>
                <input
                  className={errorState ? styles.input_error : ''}
                  value={grzState}
                  onChange={handleChangeGrz2}
                  type="text"
                  placeholder={rusFormatState ? 'X 000 XX 00' : 'XXXXXXXX'}>
                </input>
              </Row>

              <button
                onClick={grzChangerMode}
                className={`${styles.form_input_btn_country} ${errorState ? styles.country_btn_w_error : ''} ${openCountryState ? styles.form_input_btn_country_active : ''} `}
              >
                {!rusFormatState ? <RussianFlagSvg/> : <ForeinSvg/>}
              </button>

            </div>

            <div className={`${styles.error} ${errorState ? '' : styles.hidden}`}>Некорректный формат номера
              автомобиля
            </div>

            <div className={styles.phone_input}>
              <Phone2Svg/>
              <input
                ref={inputPhone}
                onChange={handleChangeValue}
                type="text"
                placeholder="+7 (___) ___-__-__"
                className={errorPhoneValue ? "" : styles.input_error}
              >
              </input>
            </div>
            <div className={`${styles.error} ${errorPhoneValue ? styles.hidden : ""}`}>Некорректный формат номера
              телефона
            </div>

            <div className={`${errorPhoneValue ? styles.form_checkbox : ""} ${styles.hidden}`}>
              <label>
                <input type="checkbox" onChange={() => setCheckedState((s) => (!s))}/>
                <Row>
                  <div
                    className={`${styles.checkbox} ${checkedState ? styles.checkbox__active : ""} `}
                    aria-hidden="true"
                  />
                  <div className={styles.form_checkbox__text}>
                    Принимаю условия
                    <button
                      onClick={() => {
                        setOpenPersonalDataState((s) => (!s))
                      }}
                      className={styles.form_checkbox__personal_data}
                    >
                      пользовательского соглашения
                    </button>
                  </div>
                </Row>
              </label>
            </div>

            <NavLink
              to={canNext() ? `/${zone}/subscription/${subscriptionCode}/payment?${searchParams.toString()}` : ''}
              className={`${styles.form_btn} ${canNext() ? styles.form_btn__active : ''}`}
            >
              <CheckSvg/>
              <span>Подтвердить</span>
            </NavLink>
          </div>
        </Container>
        <PersonalData visible={openPersonalDataState}/>
      </section>
    </Layout>
  )
}

export default SubscriptionInputsPage;

