import Title from "../../ui/title/Title"
import styles from './Loader.module.scss'


const LoaderPay = () => {
	return (
		<div className={styles.container}>
			<Title text="Пожалуйста подождите, производится оплата" center />
			<LoaderCircle />
		</div>
	)
}

export default LoaderPay

export function LoaderCircle() {
	return (
		<div className={styles.loading}>
			<div className={styles.loading_back} />
			<div className={styles.loading_svg}>
				<svg className={styles.circular_loader} viewBox="25 25 50 50" >
					<circle className={styles.loader_path} cx="50" cy="50" r="14" fill="none" stroke="#00DFAB" strokeWidth="2.5" />
				</svg>
			</div>
		</div>
	)
}
