import ApiCommon from "api/ApiCommon"

export interface ParkingSessionResultType {
	date: string;
	grz: string;
	sessionID: string;
	paidTo: string;
}

export type ParkingSessionType = {
	result: ParkingSessionResultType[];
}

export type ParkingSessionPhotoType = {
	result: string
}

export type ParkingSessionCostType = {
	result: {
		filter(): unknown
		cost: number,
		timeout: string, // 2022-12-21T17:22:59
		timein: string, //2022-12-21T17:22:59
		reason?: string
	}
}

export enum PaymentType {
	'grz' = 'grz',
	'phone' = 'phone',
	'subscription' = 'subscription',
}

export function getParkingSession(zone: string | undefined, typeID: PaymentType, valueID: string | undefined): Promise<ParkingSessionType> {
	return ApiCommon.get(`/sessions?typeID=${typeID}&valueID=${valueID}`, zone)
}

export function getParkingSessionPhoto(zone: string | undefined, sessionID: any): Promise<ParkingSessionPhotoType> {
	return ApiCommon.get(`/foto?sessionID=${sessionID}`, zone)
}

// TODO выпилить
export function getParkingSessionCost(zone: string | undefined, sessionID: any): Promise<ParkingSessionCostType> {
	return ApiCommon.get(`/cost?sessionID=${sessionID}`, zone)
}
