import Title from "../ui/title/Title";
import { Row, SectionContainer } from "../ui/grid";
import styles from './Payment.module.scss';
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getParkingSessionCost } from "api/query/ParkingSession";
import { getSubscription } from "api/query/Subscription";
import Layout from '../layout'
import PaymentCheckSvg from "../../svg/PaymentCheckSvg";
import EndCheckSvg from "../../svg/EndCheckSvg";
import { PaymentInfoTypeResp } from "../../api/query/Payment";

const PaymentPage = ({ paymentType }: { paymentType: PaymentInfoTypeResp }) => {
  const { zone, sessionID } = useParams();
  const [searchParams] = useSearchParams();
  const grzParam = searchParams.get("grz");
  const phoneParam = searchParams.get("phone");

  const [doPay, setDoPay] = useState<boolean>(true);
  const [costState, setCostState] = useState<number>(-1);
  const [timeEndState, setTimeEndState] = useState<string>('--:--');
  const [timeStartState, setTimeStartState] = useState<string>('--:--');
  const [diffMinutesState, setDiffMinutesState] = useState<number>(-1);
  const [costReasonState, setCostReasonState] = useState<string>('');

  useEffect(() => {
    if (!sessionID) return;
    if (paymentType === PaymentInfoTypeResp.abonnement) {
      getSubscription(zone)
        .then((data) => {
          (data.result || []).forEach((item) => {
            if (sessionID === item.code) {
              setCostState(item.price);
              setTimeEndState(item.timeend.replace('T', ' '));
              setTimeStartState(item.timestart.replace('T', ' '));
            }
          });
          if(data.result) setDoPay(false)
        })
    } else {
      getParkingSessionCost(zone, sessionID)
        .then((data) => {
          if(data.result) setDoPay(false)
          setCostState(data.result.cost);
          const timeIn = data.result.timein.split('T')[1];
          const timeOut = data.result.timeout.split('T')[1];
          if (timeIn) setTimeStartState(timeIn.substring(0, timeIn.length - 3));
          if (timeOut) setTimeEndState(timeOut.substring(0, timeOut.length - 3));
          const diff = Date.parse(data.result.timeout || '') - Date.parse(data.result.timein || '');
          setDiffMinutesState(Math.floor(diff / 60000));
          if(data.result.cost === 0 && data.result.reason ) setCostReasonState(data.result.reason)
          console.log('test')
        })
    }
  }, [zone, sessionID, paymentType])

  let link = '';
  if (paymentType === PaymentInfoTypeResp.session) {
    link = doPay ? '' : `/${zone}/session/${sessionID}/payment-card`;
  } else if (paymentType === PaymentInfoTypeResp.abonnement) {
    const params = new URLSearchParams({
      grz: grzParam ?? '',
      phone: phoneParam ?? '',
    });
    link = doPay ? '' : `/${zone}/subscription/${sessionID}/payment-card?${params.toString()}`;
  }
  
  return (
    <Layout>
      <SectionContainer fixHeight>
        <Title text="Оплатите парковку" center />
        <div className={styles.check}>
          <div className={styles.check_start}>
            <PaymentCheckSvg />
          </div>
          <div className={styles.check__price}>
            <div className={styles.check__price_title}>Всего к оплате:</div>
            <div className={styles.check__price_price}>{costState >= 0 ? costState : '-'} ₽</div>
            <div className={styles.hidden}>
              {paymentType === PaymentInfoTypeResp.session ? (
                <div className={styles.check__price_subscribe}>
                  <Row center>
                    <div className={styles.check__price_subscribe_text}>
                      С абонементом {costState >= 0 ? costState * 0.75 : '-'} ₽
                    </div>
                    <NavLink to="subscription" className={styles.check__price_subscribe_btn}>
                      Купить
                    </NavLink>
                  </Row>
                </div>
              ) : null}
            </div>
          </div>

          {paymentType === PaymentInfoTypeResp.abonnement ? (
            <div className={styles.check_info}>
              <div className={styles.check_info_subscription_item}>
                <div className={styles.check_info_subscription_item_title}>Дата начала абонемента:</div>
                <div className={styles.check_info_subscription_item_time}>{timeStartState}</div>
              </div>
              <div className={styles.check_info_subscription_item}>
                <div className={styles.check_info_subscription_item_title}>Дата окончания абонемента:</div>
                <div className={styles.check_info_subscription_item_time}>{timeEndState}</div>
              </div>
            </div>
          ) : (
            <div className={styles.check_info}>
              <div className={styles.check_info_item}>
                <div className={styles.check_info_item_title}>Начало парковки:</div>
                <div className={styles.check_info_item_time}>{timeStartState ? timeStartState : 0}</div>
              </div>
              <div className={styles.check_info_item}>
                <div className={styles.check_info_item_title}>Конец парковки:</div>
                <div className={styles.check_info_item_time}>{timeEndState ? timeEndState : 0}</div>
              </div>
              <div className={styles.check_info_item}>
                <div className={styles.check_info_item_title}>Время парковки:</div>
                <div className={styles.check_info_item_time}>
                  {diffMinutesState < 0 ? '-' : (
                    <>
                      {diffMinutesState < 60 ? diffMinutesState : Math.floor(diffMinutesState / 60)}<span> {diffMinutesState < 60 ? 'м' : 'ч'}</span>
                    </>
                  )}
                </div>
              </div>
              {costState === 0 &&
                <div className={styles.check_info_item}>
                  <div className={styles.check_info_item_title}><b>Оплата не требуется. Причина: {costReasonState}</b></div>
                </div>
              }
            </div>
          )}

{costState !== 0 ?
  <div className={styles.check_info} translate='no'>
    <NavLink className={`${styles.check_info_btn} ${doPay ? styles.btn_notactive : ""}`} to={link}>Оплатить</NavLink>
  </div>
:
  <div className={styles.check_info} translate='no'>
    <NavLink className={`${styles.check_info_btn} ${doPay ? styles.btn_notactive : ""}`} to={`/${zone}/`}>Ok</NavLink>
  </div>
}
          
        </div>
        <div className={styles.check_end}>
          <EndCheckSvg />
      
      
        </div>

      </SectionContainer>
    </Layout>
  )
}

export default PaymentPage;
