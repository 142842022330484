import {getSubscription, SubscriptionItemResp} from "api/query/Subscription";
import { useEffect, useState } from "react";
import {NavLink, useParams} from "react-router-dom";
import { SectionContainer } from "../ui/grid";
import Title from "../ui/title/Title";
import SubcriptionItem from "./SubcriptionItem/SubcriptionItem";
import styles from './Subscription.module.scss'
import Layout from '../layout'
import {LoaderCircle} from "../payment/loader/LoaderCircle";

const SubscriptionPage = () => {
	const { zone } = useParams();

	const [selectedState, setSelectedState] = useState<string>("04");
	const [subscriptionResState, setSubscriptionResState] = useState<SubscriptionItemResp[]>([]);

	useEffect(() => {
		getSubscription(zone)
			.then((data) => {
				setSubscriptionResState(data.result || [])
			})
	}, [zone])

	return (
		<Layout>
			<SectionContainer>
				<Title text="Выберите абонемент" />
				{ Array.isArray(subscriptionResState) && subscriptionResState.length > 0 ? (
					<>
						<div className={styles.items}>
							{subscriptionResState.map((subscription) =>
								<SubcriptionItem
									elClass={`${subscription.code}`}
									title={subscription.name}
									subtitle={subscription.description}
									price={subscription.price}
									changed={() => {
										setSelectedState(subscription.code);
									}}
									isSelected={selectedState === subscription.code}
								/>
							)}
						</div>
						<NavLink className={styles.btn} to={`/${zone}/subscription/${selectedState}`}>
							Купить
						</NavLink>
					</>
				) : <LoaderCircle /> }
			</SectionContainer>
		</Layout>
	)
}

export default SubscriptionPage;
