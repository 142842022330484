export class PhoneHelper {
  public static handleChangeValue(inputPhone: string) {
    const r = {
      clear: '',
      formatted: '',
    };
    r.clear = inputPhone
      .replace(/^9/, '+79') // На телефонах сяоми при вводе телефона 9 следующая цифра задваивается
      .replace(/^8/, '+7')
      .replaceAll(/[\s-()]/g, '');
    // console.info(r.clear);

    const phoneNumbers = r.clear
      .match(/(\+\d)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

    if (Array.isArray(phoneNumbers)) {
      if (phoneNumbers[5]) {
        r.formatted = `${phoneNumbers[1]} (${phoneNumbers[2]}) ${phoneNumbers[3]}-${phoneNumbers[4]}-${phoneNumbers[5]}`;
      } else if (phoneNumbers[4]) {
        r.formatted = `${phoneNumbers[1]} (${phoneNumbers[2]}) ${phoneNumbers[3]}-${phoneNumbers[4]}`;
      } else if (phoneNumbers[3]) {
        r.formatted = `${phoneNumbers[1]} (${phoneNumbers[2]}) ${phoneNumbers[3]}`;
      } else if (phoneNumbers[2]) {
        r.formatted = `${phoneNumbers[1]} (${phoneNumbers[2]}`;
      } else if (phoneNumbers[1]) {
        r.formatted = phoneNumbers[1] === '+7' ? `${phoneNumbers[1]}` : '+';
      }
    } else if (r.clear === '+') {
      r.formatted = r.clear;
    }
    return r;
  }
}
