import styles from './Title.module.scss'

type ITitle = {
  text: string,
  center?: boolean,
  sx?: boolean
}

const Title = ({ text, center, sx }: ITitle) => {
  return (
    <div 
      className={`${styles.title} ${center ? styles.center : ''} ${sx ? styles.sx : ''}`} 
      dangerouslySetInnerHTML={{ __html: text }} 
    />
  )
}

export default Title;