import Title from "../../ui/title/Title";
import { SectionContainer } from "../../ui/grid";
import styles from './CardPayment.module.scss'
import { useEffect, useState } from "react";
import { getPaymentMethods, pay, PaymentInfoTypeResp, PaymentMethodsType } from "api/query/Payment";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getSubscription, subscriptionPay } from "api/query/Subscription";
import Layout from '../../layout'
import { FiscalCheck } from "../../FiscalCheck";

const CardPaymentPage = ({ paymentType }: { paymentType: PaymentInfoTypeResp }) => {
  const navigate = useNavigate();
  const { zone, sessionID } = useParams();
  const [searchParams] = useSearchParams();
  const grzParam = searchParams.get("grz");
  const phoneParam = searchParams.get("phone");

  const [emailValue, setEmailValue] = useState('');
  const [doQueryState, setDoQueryState] = useState<boolean>(false);
  const [selectedMethod, setSelectedMethod] = useState<number>(0);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodsType>();

  const radioChangeHandler = (key: number) => {
    setSelectedMethod(key);
  };

  useEffect(() => {
    getPaymentMethods(zone).then((data) => {
      setPaymentMethods(data);
    });
  }, [zone]);

  const isQR = paymentMethods?.result[selectedMethod].type === 'QR'

  const doPay = () => {
    if (!(paymentMethods && sessionID) || doQueryState) return;
    setDoQueryState(true);
    setTimeout(() => {
      setDoQueryState(false);
    }, 3e4);
    const paymentName = paymentMethods.result[selectedMethod].paymentName;
    if (paymentType === PaymentInfoTypeResp.abonnement) {
      getSubscription(zone).then((data) => {
        const subscription = data.result.filter((item) => (item.code === sessionID));
        if (!(subscription && subscription[0] && grzParam && phoneParam)) return;
        subscriptionPay(zone, grzParam, phoneParam, paymentName, sessionID, subscription[0].price)
          .then((data) => {
            window.location.href = (data.result.redirectURL);
            const splitUrl = data.result.redirectURL.split('/')[3]
            const paymentID = splitUrl.split('?')[0]
            if (isQR) {
              navigate(`../../${zone}/callback/SuccessPage.html?paymentID=${paymentID}`)
            }
          });
      })
    } else if (paymentType === PaymentInfoTypeResp.session) {
      const isUseFiscal = emailValue !== '' ? emailValue : undefined
      pay(zone, sessionID, paymentName, isUseFiscal)
        .then((data) => {
          window.location.href = (data.result.redirectURL);
          const splitUrl = data.result.redirectURL.split('/')[3]
          const paymentID = splitUrl.split('?')[0]
          if (isQR) {
            navigate(`../../${zone}/callback/SuccessPage.html?paymentID=${paymentID}&type=qr`)
          }
        });
    }
  }

  return (
    <Layout>
      <SectionContainer fixHeight>
        <div className={styles.btnWrap}>
          <div className={styles.wrapItem}>
            <Title text="Выберите способ оплаты" />
            <div className={styles.items}>
              {paymentMethods?.result.map((method: any, key: number) =>
                <div
                  key={key}
                  className={`${styles.item} ${selectedMethod === key ? styles.item__active : ''}`}
                  onClick={() => { radioChangeHandler(key) }}
                >
                  <label className={styles.label_wrap} onClick={() => { radioChangeHandler(key) }}>
                    <input type="radio" checked={selectedMethod === key} className={`${key}`} />
                    <span className={`${key} ${selectedMethod === key ? styles.radio__active : styles.radio}`} />
                  </label>
                  <img src={method.imgURL} alt="" className={`${key}`} onClick={() => { radioChangeHandler(key) }} />
                  <div className={styles.itemName} onClick={() => { radioChangeHandler(key) }}>{method.paymentViewName}</div>
                  {styles.itemDescription ? (
                    <div className={styles.itemDescription} onClick={() => { radioChangeHandler(key) }}>
                      {method.paymentDescription}
                    </div>
                  ) : null}
                </div>
              )}

            </div>
          </div>

          {paymentMethods?.result[selectedMethod].useFiscal ?
            <FiscalCheck setEmailValue={setEmailValue} /> : null}

          <button
            type="button"
            onClick={doPay}
            className={`${styles.btn} ${doQueryState ? styles.btn_notactive : ''}`}
          >
            Завершить оплату
          </button>
        </div>
      </SectionContainer>
    </Layout>
  )
}

export default CardPaymentPage;
