import styles from './SubcriptionItem.module.scss'

type ISubcriptionItem = {
	title: string,
	subtitle: string,
	price: number,
	elClass: string,
	isSelected: boolean,
	changed: any
}

const SubcriptionItem = ({ title, subtitle, price, elClass, isSelected, changed }: ISubcriptionItem) => {

	return (
		<div className={`${elClass} ${styles.item}`} onClick={changed}>
			<label>
				<input type="radio" className={elClass} onChange={changed} checked={isSelected} />
				<span className={`${elClass} ${styles.radio} ${isSelected ? styles.radio__active : ''}`}/>
			</label>
			<div className={`${elClass} ${styles.item_title}`}>{title}</div>
			<div className={`${elClass} ${styles.item_subtitle}`} dangerouslySetInnerHTML={{ __html: subtitle }} />
			<div className={`${elClass} ${styles.item_price}`}>{price}</div>
		</div>
	)
}

export default SubcriptionItem;
