export default class ApiCommon {
  static baseUrl = '/api';
  static utf8 = 'application/json;charset=UTF-8';
  static Method = {
    GET: 'GET',
    POST: 'POST',
  }
  private static lazy = new Map<string, any>();

  static async get(path: string, zone: string | undefined) {
    const option = {
      method: ApiCommon.Method.GET,
      headers: {
        'Content-Type': ApiCommon.utf8,
        'zonenumber': zone ?? '',
      },
    };
    return await fetch(`${ApiCommon.baseUrl}${path}`, option)
      .then(response => (response.json()))
      .catch(error => (error));
  }

  static async getLazy(path: string, zone: string | undefined) {
    const key = `${path}_${zone}`;
    const r = ApiCommon.lazy.get(key);
    if (r) {
      return r;
    }
    return ApiCommon.get(path, zone).then((r) => {
      // @ts-ignore
      ApiCommon.lazy.set(key, r);
      return r;
    });
  }

  static async post(path: string, zone: string | undefined, request: any) {
    const option = {
      method: ApiCommon.Method.POST,
      headers: {
        'Content-Type': ApiCommon.utf8,
        'zonenumber': zone ?? '',
      },
      body: JSON.stringify(request),
    };
    return await fetch(`${ApiCommon.baseUrl}${path}`, option)
      .then(response => {
        return response.json()
      })
      .catch(error => {
        return error
      });
  }
}
