import Title from "../ui/title/Title";
import {useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import {Container} from "../ui/grid";
import AutoItem from "./AutoItem/AutoItem";
import styles from './SessionSelect.module.scss'
import {getParkingSession, ParkingSessionResultType, PaymentType} from "api/query/ParkingSession";
import Layout from '../layout'
import {LoaderCircle} from "../payment/loader/LoaderCircle";

const SessionSelectPage = ({paymentType}: { paymentType: PaymentType }) => {
  const {zone, valueId} = useParams();

  const [checkGrzState, setCheckGrzState] = useState<number>(0);
  const [sessionsState, setSessionsState] = useState<ParkingSessionResultType[]>([])

  useEffect(() => {
    getParkingSession(zone, paymentType, valueId)
      .then((data) => {
        setSessionsState(data.result ?? [])
      });
  }, [zone, valueId, paymentType])

  return (
    <Layout>
      <section className={styles.grz_auto}>
        <Container>
          <div className={styles.wrapper}>
            <Title text="Выберите ваш транспорт"/>
            {(sessionsState || []).length > 0 ? (
              <>
                <div className={styles.items}>
                  {(sessionsState || []).map((session, key: number) =>
                    <AutoItem
                      key={session.sessionID}
                      commonClass={key.toString()}
                      sessionId={session.sessionID}
                      dateFull={session.date}
                      changed={(e: any) => {
                        const target = e.target as Element;
                        setCheckGrzState(Number(target.classList.value[0]));
                      }}
                      isSelected={checkGrzState === key}
                      zone={zone}
                    />
                  )}
                </div>
                <NavLink
                  to={`confirmation?sessionID=${sessionsState[checkGrzState] ? sessionsState[checkGrzState].sessionID : ''}`}
                  className={styles.btn}
                >
                  Далее
                </NavLink>
              </>
            ) : <LoaderCircle/>}
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default SessionSelectPage;
