import ApiCommon from "api/ApiCommon"

export type PaymentMethodsType = {
	result: [{
		paymentName: string,
		paymentViewName: string,
		paymentDescription: string,
		imgURL: string,
		type: string,
		useFiscal: boolean
	}]
}

export type PayType = {
	result: {
		refill: number,
		orderNumber: string,
		redirectURL: string,
		description: string
	}
}

export type PayStatusType = {
	result: {
		status: string
	}
}

export type SendCheckType = {
	errorMessage?: string,
	result?: string
}

export enum PaymentInfoTypeResp {
	session = 'session',
	abonnement = 'abonnement',
}

export type PaymentInfoResp = {
	result: {
		orderNumber: string,
		zoneNumber: string,
		grz: string,
		phone: string,
		paymentService: string,
		paymentType: PaymentInfoTypeResp,
		sessionID: string,
		timeOut: string, // 2022-12-20T16:19:59
	}
}

export function getPaymentMethods(zone: string | undefined): Promise<PaymentMethodsType> {
	return ApiCommon.get('/paymentMethods', zone);
}

export function pay(zone: string | undefined, sessionID: string, paymentService: string, email?: string): Promise<PayType> {
	return ApiCommon.post('/pay', zone, { 
		'sessionID': sessionID,
		'paymentService': paymentService,
		'email': email
	});
}

export function getPayStatus(zone: string | undefined, orderNumber: string): Promise<PayStatusType> {
	return ApiCommon.get(`/orderStatus?orderNumber=${orderNumber}`, zone);
}

export function sendCheck(zone: string | undefined, orderNumber: string, recipient: string): Promise<SendCheckType> {
	return ApiCommon.get(`/sendCheck?orderNumber=${orderNumber}&recipient=${recipient}`, zone);
}

export function getPaymentInfo(paymentID: any): Promise<PaymentInfoResp> {
	return ApiCommon.get(`/paymentInfo?paymentID=${paymentID}`, undefined);
}
