export default function OperatorSvg() {
  return (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#fff"/>
      <path
        d="M19.878 15.194c.064 0 .126.003.188.007l-.206.504-.073.208-.012.04c-.307 1.038-.045 2.21.747 2.99l.462.455c-.149.407-.363.786-.633 1.12l-.49-.171-.206-.058-.042-.01a2.601 2.601 0 0 0-1.563.147 2.707 2.707 0 0 0-1.217 1.026l-.729 1.11-.109.187-.02.035c-.28.54-.39 1.155-.318 1.763-.36.038-.72.056-1.082.056C9.818 24.603 7 21.421 7 18.33v-.784c0-.624.24-1.222.666-1.663a2.234 2.234 0 0 1 1.607-.69h10.605zM14.575 5c1.105 0 2.164.454 2.946 1.263a4.39 4.39 0 0 1 1.22 3.05 4.39 4.39 0 0 1-1.22 3.05 4.096 4.096 0 0 1-2.946 1.262 4.096 4.096 0 0 1-2.946-1.263 4.39 4.39 0 0 1-1.22-3.05 4.39 4.39 0 0 1 1.22-3.049A4.096 4.096 0 0 1 14.575 5z"
        fill="#03B6B7"/>
      <path
        d="m19.423 21.85 1.121.385.183.063a5.11 5.11 0 0 0 1.303-1.539c.299-.53.493-1.068.585-1.614l.038-.272-1.086-1.067c-.176-.177-.301-.4-.362-.646a1.419 1.419 0 0 1 .02-.746l.045-.134.494-1.203c.101-.266.29-.487.532-.625.243-.138.524-.184.796-.13l.117.031.617.204c.612.204 1.081.746 1.233 1.427.359 1.621-.073 3.594-1.295 5.918-1.222 2.321-2.56 3.719-4.017 4.188a1.67 1.67 0 0 1-.858.044 1.703 1.703 0 0 1-.772-.39l-.121-.116-.468-.486a1.423 1.423 0 0 1-.218-1.615l.069-.116.708-1.076c.116-.18.276-.325.464-.42.188-.096.396-.139.605-.124.048.004.096.012.143.022l.124.036z"
        fill="#03B6B7"/>
    </svg>
  );
}
