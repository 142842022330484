import { BrowserRouter, Route, Routes } from "react-router-dom"
import './App.css';
import './styles/globals.scss'
import PayMethodsPage from './components/payMethods/PayMethodsPage';
import GrzPage from "./components/grzPayment/GrzPage";
import SessionSelectPage from "./components/SessionSelect/SessionSelectPage";
import SessionConfirmationPage from "./components/SessionConfirmation/SessionConfirmationPage";
import ErrorPage from "./components/error/ErrorPage";
import PhonePaymentPage from "./components/phonePayment/PhonePaymentPage";
import SubscriptionPage from "./components/subscription/SubscriptionPage";
import PaymentPage from "./components/payment/PaymentPage";
import CardPaymentPage from "./components/payment/cardPayment/CardPaymentPage";
import PaymentResultPage from "./components/payment/paymentResult/PaymentResultPage";
import SubscriptionInputsPage from "./components/subscription/SubscriptionInputs/SubscriptionInputsPage";
import PaymentResultPlugPage from "./components/payment/paymentResult/PaymentResultPlugPage";
import {PaymentType} from "./api/query/ParkingSession";
import {PaymentInfoTypeResp} from "./api/query/Payment";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/callback/SuccessPage.html" element={<PaymentResultPlugPage />} />
        <Route path="/:zone/callback/SuccessPage.html" element={<PaymentResultPlugPage />} />
        <Route path="/:zone/result/:paymentID" element={<PaymentResultPage />} />

        <Route path="/:zone/" element={<PayMethodsPage />} />

        <Route path="/:zone/grz" element={<GrzPage />} />
        <Route path="/:zone/grz/:valueId" element={<SessionSelectPage paymentType={PaymentType.grz} />} />
        <Route path="/:zone/grz/:valueId/confirmation" element={<SessionConfirmationPage paymentType={PaymentType.grz} />} />
        <Route path="/:zone/grz/:valueId/error" element={<ErrorPage paymentType={PaymentType.grz} />} />

        <Route path="/:zone/phone" element={<PhonePaymentPage />} />
        <Route path="/:zone/phone/:valueId" element={<SessionSelectPage paymentType={PaymentType.phone} />} />
        <Route path="/:zone/phone/:valueId/confirmation" element={<SessionConfirmationPage paymentType={PaymentType.phone} />} />
        <Route path="/:zone/phone/:valueId/error" element={<ErrorPage paymentType={PaymentType.phone} />} />

        <Route path="/:zone/subscription" element={<SubscriptionPage />} />
        <Route path="/:zone/subscription/:subscriptionCode" element={<SubscriptionInputsPage />} />

        <Route path="/:zone/session/:sessionID/payment" element={<PaymentPage paymentType={PaymentInfoTypeResp.session} />} />
        <Route path="/:zone/session/:sessionID/payment-card" element={<CardPaymentPage paymentType={PaymentInfoTypeResp.session} />} />

        <Route path="/:zone/subscription/:sessionID/payment" element={<PaymentPage paymentType={PaymentInfoTypeResp.abonnement} />} />
        <Route path="/:zone/subscription/:sessionID/payment-card" element={<CardPaymentPage paymentType={PaymentInfoTypeResp.abonnement} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
