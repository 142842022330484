import styles from './Row.module.scss'

type IRow = {
  spaceBetween?: boolean,
  center?: boolean,
  nowrap?: boolean,
  children: any,
  className?: string
}

const Row: React.FC<IRow> = ({ spaceBetween, center, children, nowrap, className }) => {
  const cssClass = `styles.${className}`
  return (
    <div className={`${styles.row} ${cssClass} ${spaceBetween ? styles.space_between : ''} ${center ? styles.center : ''} ${nowrap ? styles.nowrap : ''}`}>
      {children}
    </div>
  );
}

export default Row;