import styles from './GrzPage.module.scss'
import {useEffect, useState} from "react";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import Title from "../ui/title/Title";
import {Container, Row} from "../ui/grid";
import PersonalData from "../personal-data/PersonalData";
import {GrzHelper} from "../../helper/GrzHelper";
import {getParkingSession, PaymentType} from 'api/query/ParkingSession';
import Layout from '../layout'
import CheckSvg from "../../svg/CheckSvg";
import PaymentAutoSvg from "../../svg/PaymentAutoSvg";
import Triangle2Svg from "../../svg/Triangle2Svg";
import ForeinSvg from "../../svg/ForeinSvg";
import RussianFlagSvg from "../../svg/RussianFlagSvg";

const GrzPage = () => {
  const navigate = useNavigate();
  const { zone } = useParams();

  const [openCountryState, setOpenCountryState] = useState(false);
  const [rusFormatState, setRusFormatState] = useState(true);
  const [checkedState, setCheckedState] = useState(false);
  const [openPersonalDataState, setOpenPersonalDataState] = useState(false);
  const [validRusState, setValidRusState] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [grzState, setGrzState] = useState('');
  const [grzStateWithoutSpace, setGrzStateWithoutSpace] = useState('');

  const handleChangeGrz2 = (e: any) => {
    const grzValue = e.target.value;
    if (rusFormatState) {
      const grzWithoutSpaces = GrzHelper.clearRus(grzValue);
      const grzArr = grzWithoutSpaces.match(/^([\s\S])([\s\S]{0,3})([\s\S]{0,2})([\s\S]{0,3})/);
      const grzFormatted = (Array.isArray(grzArr) ? grzArr : []).filter((v, i) => (i > 0 && v)).join(' ');
      setGrzState(grzFormatted);
    } else {
      setGrzState(grzValue);
    }
  }

  useEffect(() => {
    setGrzStateWithoutSpace(grzState.replace(/\s/g, ''));
    const validRus = GrzHelper.testRus(grzState);
    setErrorState(!openCountryState && !!grzState && grzState.length >= 11 && !validRus);
    setValidRusState(validRus);
  }, [grzState, openCountryState]);

  const grzChangerMode = () => {
    setRusFormatState((s) => (!s));
    setOpenCountryState(!openCountryState);
    setGrzState('');
    setErrorState(false)
  }

  const canNext = () => {
    return (!openCountryState && validRusState && !errorState) || (openCountryState);
  }

  return (
    <Layout>
      <section className={`${styles.section} ${styles.fixHeight}`}>
        <Container>
          <div className={styles.img}>
            <PaymentAutoSvg />
          </div>
          <div className={styles.form}>

            <Title text="Введите номер автомобиля" />

            <div className={styles.form_input}>
              <Row center>
                <button onClick={() => {
                  setOpenCountryState(!openCountryState)
                }} className={`${styles.form_input_btn} ${errorState ? styles.btn_w_error : ''}`}>
                  <Row center>
                    {rusFormatState ? <RussianFlagSvg /> : <ForeinSvg />}
                    <Triangle2Svg />
                  </Row>
                </button>
                <input
                  className={errorState ? styles.input_error : ''}
                  value={grzState}
                  onChange={handleChangeGrz2}
                  type="text"
                  placeholder={rusFormatState ? 'X 000 XX 00' : 'XXXXXXXX'}>
                </input>
              </Row>

              <button
                onClick={grzChangerMode}
                className={`${styles.form_input_btn_country} ${errorState ? styles.country_btn_w_error : ''} ${openCountryState ? styles.form_input_btn_country_active : ''} `}
              >
                {!rusFormatState ? <RussianFlagSvg /> : <ForeinSvg />}
              </button>

            </div>

            <div className={`${styles.error} ${errorState ? '' : styles.hidden}`}>Некорректный формат номера автомобиля</div>

            <div className={`${styles.form_checkbox} ${styles.hidden}`}>
              <label>
                <input type="checkbox" onChange={() => setCheckedState((s) => (!s))} />
                <Row>
                  <div
                    className={`${styles.checkbox} ${checkedState ? styles.checkbox__active : ""} `}
                    aria-hidden="true"
                  />
                  <div className={styles.form_checkbox__text}>
                    Принимаю условия
                    <button
                      onClick={() => { setOpenPersonalDataState((s) => (!s)) }}
                      className={styles.form_checkbox__personal_data}
                    >
                      пользовательского соглашения
                    </button>
                  </div>
                </Row>
              </label>
            </div>

            <NavLink
              onClick={() => {
                getParkingSession(zone, PaymentType.grz, grzStateWithoutSpace)
                  .then((data) => ('errorMessage' in data))
                  .then((error) => {
                    if (canNext()) {
                      if (error) {
                        navigate(`/${zone}/grz/${grzState}/error`);
                      } else {
                        navigate(`/${zone}/grz/${grzStateWithoutSpace}`);
                      }
                    }
                  });
              }}
              to=""
              className={`${styles.form_btn} ${canNext() ? styles.form_btn__active : ''}`}
            >
              <CheckSvg />
              <span>Подтвердить</span>
            </NavLink>

          </div>
        </Container>
        <PersonalData visible={openPersonalDataState} />
      </section>
    </Layout>
  )
}

export default GrzPage;
