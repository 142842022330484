import { Container, Row } from "../ui/grid";
import styles from './PayMethods.module.scss'
import { useState } from "react";
import {NavLink} from "react-router-dom";
import Title from "../ui/title/Title";
import Layout from '../layout'
import PaymentAutoSvg from "../../svg/PaymentAutoSvg";
import PhonePaymentSvg from "../../svg/PhonePaymentSvg";
import BuySubscriberSvg from "../../svg/BuySubscriberSvg";
import IcInfoSvg from "../../svg/IcInfoSvg";
import TriangleSvg from "../../svg/TriangleSvg";
import {InfoType} from "../../api/query/Info";

function PayMethodsPage() {
  const [activeInfo, isActiveInfo] = useState(false);
  const [info, setInfo] = useState<InfoType>();
  return (
    <Layout infoListener={setInfo}>
      <section className={styles.payment}>
        <Container>
          <div className={styles.payment__methods}>
            <button onClick={() => { isActiveInfo(!activeInfo) }} className={`${styles.payment__methods_info_btn} ${activeInfo ? styles.payment__methods_info_btn_active : ''} `}>
              <IcInfoSvg />
            </button>

            <div className={`${styles.payment__methods_info} ${activeInfo ? styles.payment__methods_info_del_hidden : ''} `}>

              <div className={styles.payment__methods_info__triangle}>
                <TriangleSvg />
              </div>
              <div className={styles.payment__methods_info__text}>
                <b>Въезд по номеру автомобиля</b> – способ въезда, при котором камера считывает номер автомобиля и пропускает вас
                <br />
                <br />
                <b>Въезд по звонку</b> – если камера вас не распознала на въезде, и вы звонили по телефону, который был указан на информационном табло
              </div>

            </div>

            <Title text="Как вы въехали" />
            <NavLink to="grz" className={styles.payment__methods_item_grs}>
              <Row center>
                <span><PaymentAutoSvg /></span>
                <span className={styles.payment__methods_item_grs_text}>по номеру автомобиля</span>
              </Row>
            </NavLink>

            <NavLink to="phone" className={styles.payment__methods_item_phone}>
              <Row center>
                <span><PhonePaymentSvg /></span>
                <span className={styles.payment__methods_item_phone_text}>по звонку</span>
              </Row>
            </NavLink>
          </div>

          { info && info.result.canPayAbonement ? (
            <div className={styles.payment___buy_subscription}>
              <NavLink to="subscription" className={styles.payment___buy_subscription_link}>
                <Row center>
                  <span><BuySubscriberSvg /></span>
                  <span className={styles.payment___buy_subscription_link_txt}>Купить абонемент</span>
                </Row>
              </NavLink>
            </div>
          ) : null }
        </Container>
      </section>
    </Layout>
  );
}

export default PayMethodsPage;
