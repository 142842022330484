export default function PhoneSvg() {
  return (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16V0z" fill="#2BC556"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16V0z" fill="#2C9248"/>
      <path
        d="m6.932 9.16 2.585-2.582A1.962 1.962 0 0 1 10.908 6a1.96 1.96 0 0 1 1.393.578l2.782 2.781a1.964 1.964 0 0 1 0 2.784l-2.034 2.034a9.558 9.558 0 0 0 1.954 2.818 9.594 9.594 0 0 0 2.817 1.961l2.035-2.034a1.963 1.963 0 0 1 2.783 0l2.784 2.78A1.963 1.963 0 0 1 26 21.095c0 .526-.206 1.021-.578 1.393l-2.58 2.58a3.19 3.19 0 0 1-2.764.888c-3.272-.538-6.517-2.279-9.136-4.895-2.616-2.614-4.354-5.857-4.9-9.139a3.194 3.194 0 0 1 .89-2.762z"
        fill="#fff"/>
    </svg>
  );
}
