import ApiCommon from "api/ApiCommon"

export interface SubscriptionItemResp {
	name: string;
	code: string;
	description: string;
	timestart: string;
	timeend: string;
	price: number;
}

export interface SubscriptionResp {
	result: SubscriptionItemResp[];
}

export type subscriptionPayType = {
	result: {
		refill: number,
		orderNumber: string,
		description: string,
		redirectURL: string
	}
}

export function getSubscription(zone: string | undefined): Promise<SubscriptionResp> {
	return ApiCommon.get('/subscription', zone)
}

export function subscriptionPay(zone: string | undefined, grz: string, phone: string, paymentService: any, codeSubscription: string, amount: any): Promise<subscriptionPayType> {
	let body = {
		"grz": grz,
		"phone": phone,
		"paymentService": paymentService,
		"codeSubscription": codeSubscription,
		"amount": amount
	}
	return ApiCommon.post(`/paymentSubscription`, zone, body)
}
