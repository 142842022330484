import { Container, Row } from "../ui/grid"
import Title from "../ui/title/Title"
import {NavLink, useNavigate, useParams} from "react-router-dom"
import styles from './PhonePayment.module.scss'
import { useEffect, useRef, useState } from "react"
import PersonalData from "../personal-data/PersonalData"
import {getParkingSession, PaymentType} from "api/query/ParkingSession"
import Layout from '../layout'
import PhonePaymentSvg from "../../svg/PhonePaymentSvg";
import Phone2Svg from "../../svg/Phone2Svg";
import CheckSvg from "../../svg/CheckSvg";
import {PhoneHelper} from "../../helper/PhoneHelper";

const PhonePaymentPage = () => {
	const navigate = useNavigate();
	const { zone } = useParams();

	const [checked, setChecked] = useState(false);
	const [openPersonalData, setOpenPersonalData] = useState(false);

	const [phoneValue, setPhoneValue] = useState('');
	const [errorPhoneValue, setErrorPhoneValue] = useState(true);
	const inputPhone = useRef<any>();

	let phoneValueW7 = phoneValue.match(/(\+7)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
	let phoneValueW7Space = phoneValueW7 ? phoneValueW7[0] : ''
	let phoneValueW8 = phoneValue.match(/(8)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
	let phoneValueW8Space = phoneValueW8 ? phoneValueW8[0] : ''

	const handleChangeValue = () => {
		const phone = PhoneHelper.handleChangeValue(inputPhone.current.value);
		setPhoneValue(phone.clear);
		inputPhone.current.value = phone.formatted;
	}

	useEffect(() => {
		handleChangeValue();
	}, [phoneValue]);

	/* eslint-disable react-hooks/exhaustive-deps */
	/* useEffect(() => {
		if (checked) {
			setErrorPhoneValue(phoneValueW8Space.length === 11 || phoneValueW7Space.length === 12)
		}
	}, [checked]) */

	const canNext = () => {
		return (phoneValueW8Space.length === 11 || phoneValueW7Space.length === 12)
	}

	return (
		<Layout>
			<section className={`${styles.section} ${styles.fixHeight}`}>
				<Container>
					<div className={styles.img}>
						<PhonePaymentSvg />
					</div>
					<div className={styles.form}>

						<Title text="Введите номер телефона" />

						<div className={styles.form_input}>
							<Phone2Svg />
							<input
								ref={inputPhone}
								onChange={handleChangeValue}
								type="text"
								placeholder="+7 (___) ___-__-__"
								className={errorPhoneValue ? "" : styles.input_error}
							>
							</input>
						</div>
						<div className={`${styles.error} ${errorPhoneValue ? styles.hidden : ""}`}>Некорректный формат номера телефона</div>

						<div className={`${errorPhoneValue ? styles.form_checkbox : ""} ${styles.hidden}`}>
							<label>
								<input type="checkbox" onChange={() => { setChecked(!checked) }} />
								<Row>
									<div
										className={`${styles.checkbox} ${checked ? styles.checkbox__active : ""} `}
										aria-hidden="true"
									/>
									<div className={styles.form_checkbox__text}>
										Принимаю условия
										<button
											onClick={() => { setOpenPersonalData((s) => (!s)) }}
											className={styles.form_checkbox__personal_data}> пользовательского соглашения </button>
									</div>
								</Row>
							</label>
						</div>

						<NavLink
							onClick={() => {
								getParkingSession(zone, PaymentType.phone, phoneValue)
									.then((data) => ('errorMessage' in data))
									.then((error) => {
										if (canNext()) {
											if (error) {
												navigate(`/${zone}/phone/${phoneValue}/error`);
											} else {
												navigate(`/${zone}/phone/${phoneValue}`);
											}
										}
									});
							}}
							to=""
							className={`${styles.form_btn} ${canNext() ? styles.form_btn__active : ''}`}
						>
							<CheckSvg />
							<span>Подтвердить</span>
						</NavLink>

					</div>
				</Container>
				<PersonalData visible={openPersonalData} />
			</section>
		</Layout>
	)
}

export default PhonePaymentPage
