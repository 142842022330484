import { SectionContainer } from "../../ui/grid";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import Title from "../../ui/title/Title";
import styles from './PaymentResult.module.scss'
import { useEffect, useState } from "react";
import { getPaymentInfo, getPayStatus, PaymentInfoTypeResp, sendCheck, SendCheckType } from "api/query/Payment";
import LoaderPay from "../loader/LoaderCircle";
import Layout from '../../layout'

const PaymentResultPage = () => {
  const { zone, paymentID } = useParams();
  const [searchParams] = useSearchParams();
  const typeQR = searchParams.get('type') === "qr";

  const [tryAgainLinkState, setTryAgainLinkState] = useState('');
  const [openCheckPopup, setOpenCheckPopup] = useState(false);
  const [openCheckResPopup, setOpenCheckResPopup] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [payStatus, setPayStatus] = useState(false);
  const [timeToGo, setTimeToGo] = useState('--:--:--');
  const [emailValue, setEmailValue] = useState('');
  const [sendEmail, setSendEmail] = useState(false);
  const [sendCheckResult, setSendCheckResult] = useState<SendCheckType>();
  const [isLoading, setIsLoading] = useState(true);
  const [isQrError, setIsQrError] = useState(false);

  if (typeQR) {
    setTimeout(function () {
      setIsLoading(false);
    }, 10000)
  }

  useEffect(() => {
    getPaymentInfo(paymentID).then((data) => {
      setIsSubscription(data.result.paymentType === PaymentInfoTypeResp.abonnement);
      setOrderNumber(data.result.orderNumber);
      const time = data.result.timeOut.split('T')[1] ?? '--:--:--';
      setTimeToGo(time.substring(0, time.length - 3));
      if (data.result.paymentType === PaymentInfoTypeResp.session) {
        setTryAgainLinkState(`/${zone}/session/${data.result.sessionID}/payment`);
      } else {
        const params = new URLSearchParams({
          grz: data.result.grz ?? '',
          phone: data.result.phone ?? '',
        });
        setTryAgainLinkState(`/${zone}/subscription/${data.result.sessionID}/payment?${params}`);
      }
    });
  }, [paymentID, zone]);


  useEffect(() => {
    setInterval(function () {
      if (orderNumber) {
        getPayStatus(zone, orderNumber)
          .then((data) => {
            if (!typeQR) setIsLoading(false)
            if (data.result?.status != 'completed' && typeQR) {
              setIsQrError(true);
              return
            }
            setPayStatus(data.result?.status === 'completed')
          });
      }
    }, 1000)
  }, [zone, orderNumber]);


  const onInputEmailChanged = (e: any) => {
    const emailValue = e.target.value;
    setEmailValue(emailValue);
  };

  const handleSendCheck = async () => {
    sendCheck(zone, orderNumber, emailValue)
      .then((data) => {
        setSendCheckResult(data)
      });
    setSendEmail(!sendEmail);
    setOpenCheckPopup(!openCheckPopup);
    setOpenCheckResPopup(!openCheckResPopup);
  };

  return (
    <Layout>
      <div className={`${styles.pop_up} ${openCheckPopup ? '' : styles.hidden}`}>
        <div className={styles.pop_up__container}>
          <button onClick={() => {
            setOpenCheckPopup(false)
          }} className={styles.pop_up__cross}>
            <img src="/imgs/ep_close-bold.svg" alt="" />
          </button>
          <Title text="Для получения чека введите адрес электронной почты" />
          <div className={styles.input}>
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.25 6.97519e-09H16.75C17.5801 -5.43467e-05 18.3788 0.317554 18.9822 0.887671C19.5856 1.45779 19.948 2.23719 19.995 3.066L20 3.25V12.75C20.0001 13.5801 19.6824 14.3788 19.1123 14.9822C18.5422 15.5856 17.7628 15.948 16.934 15.995L16.75 16H3.25C2.41986 16.0001 1.62117 15.6824 1.01777 15.1123C0.414367 14.5422 0.0519987 13.7628 0.00500012 12.934L6.97518e-09 12.75V3.25C-5.43467e-05 2.41986 0.317554 1.62117 0.887672 1.01777C1.45779 0.414367 2.23719 0.0519987 3.066 0.00500012L3.25 6.97519e-09H16.75H3.25ZM18.5 5.373L10.35 9.663C10.258 9.71161 10.1568 9.74054 10.053 9.74795C9.94921 9.75535 9.84499 9.74106 9.747 9.706L9.651 9.664L1.5 5.374V12.75C1.50002 13.1892 1.66517 13.6123 1.96268 13.9354C2.26019 14.2585 2.6683 14.4579 3.106 14.494L3.25 14.5H16.75C17.1893 14.5 17.6126 14.3347 17.9357 14.037C18.2588 13.7392 18.4581 13.3309 18.494 12.893L18.5 12.75V5.373ZM16.75 1.5H3.25C2.81081 1.50002 2.38768 1.66517 2.06461 1.96268C1.74154 2.26019 1.54214 2.6683 1.506 3.106L1.5 3.25V3.679L10 8.152L18.5 3.678V3.25C18.5 2.81065 18.3347 2.38739 18.037 2.06429C17.7392 1.74119 17.3309 1.5419 16.893 1.506L16.75 1.5Z"
                fill="#FF8C4B" />
            </svg>

            <input type="text" placeholder="Введите ваш e-mail" onChange={onInputEmailChanged} />
          </div>
          <button onClick={handleSendCheck}
            className={`${styles.pop_up__btn} ${emailValue.length >= 10 ? styles.pop_up__btn_active : ''}`}>
            отправить
          </button>
        </div>
      </div>

      <div className={`${styles.pop_up} ${openCheckResPopup ? '' : styles.hidden}`}>
        <div className={styles.pop_up__container}>
          <button onClick={() => {
            setOpenCheckResPopup(false)
          }}>
            <img className={styles.pop_up__cross} src="/imgs/ep_close-bold.svg" alt="" />
          </button>
          <Title text={sendCheckResult?.errorMessage ? sendCheckResult.errorMessage : sendCheckResult?.result || ''} />
          <div className={styles.check_res}>
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.25 6.97519e-09H16.75C17.5801 -5.43467e-05 18.3788 0.317554 18.9822 0.887671C19.5856 1.45779 19.948 2.23719 19.995 3.066L20 3.25V12.75C20.0001 13.5801 19.6824 14.3788 19.1123 14.9822C18.5422 15.5856 17.7628 15.948 16.934 15.995L16.75 16H3.25C2.41986 16.0001 1.62117 15.6824 1.01777 15.1123C0.414367 14.5422 0.0519987 13.7628 0.00500012 12.934L6.97518e-09 12.75V3.25C-5.43467e-05 2.41986 0.317554 1.62117 0.887672 1.01777C1.45779 0.414367 2.23719 0.0519987 3.066 0.00500012L3.25 6.97519e-09H16.75H3.25ZM18.5 5.373L10.35 9.663C10.258 9.71161 10.1568 9.74054 10.053 9.74795C9.94921 9.75535 9.84499 9.74106 9.747 9.706L9.651 9.664L1.5 5.374V12.75C1.50002 13.1892 1.66517 13.6123 1.96268 13.9354C2.26019 14.2585 2.6683 14.4579 3.106 14.494L3.25 14.5H16.75C17.1893 14.5 17.6126 14.3347 17.9357 14.037C18.2588 13.7392 18.4581 13.3309 18.494 12.893L18.5 12.75V5.373ZM16.75 1.5H3.25C2.81081 1.50002 2.38768 1.66517 2.06461 1.96268C1.74154 2.26019 1.54214 2.6683 1.506 3.106L1.5 3.25V3.679L10 8.152L18.5 3.678V3.25C18.5 2.81065 18.3347 2.38739 18.037 2.06429C17.7392 1.74119 17.3309 1.5419 16.893 1.506L16.75 1.5Z"
                fill="#0E3D57" />
            </svg>

            <div className={styles.check_res_email}>{emailValue}</div>
          </div>
          <button onClick={() => {
            setOpenCheckResPopup(false)
          }} className={styles.check_res_pop_up__btn}>
            понятно
          </button>
        </div>
      </div>

      <SectionContainer fixHeight>
        {isLoading ? <LoaderPay /> : (
          <div className={styles.container}>
            <div className={styles.icon}>
              <img src={`/imgs/${isQrError ? 'refuse.svg' : payStatus ? 'Sucessful.svg' : 'refuse.svg'}`} alt="" />
            </div>
            <Title center text={`${isQrError ? 'Время оплаты истекло' : `Оплата ${payStatus ? 'прошла' : 'отклонена'}!`}`} />
            {isQrError ?
              null
              :
              payStatus && !isSubscription ? (
                <>
                  <div className={styles.subtitle}>Оплата прошла успешно, счастливого пути!</div>
                  <div className={styles.time}>Выехать до {timeToGo}</div>
                </>
              ) : null}

            <div className={styles.btns}>
              {isQrError ?
                (
                  <div className={styles.btn_refuse}>
                    <NavLink
                      to={tryAgainLinkState}>
                      попробовать еще раз
                    </NavLink>
                  </div>
                )
                :
                payStatus ? (
                  <button onClick={() => {
                    setOpenCheckPopup(true)
                  }} className={styles.btn}>
                    Получить чек
                  </button>
                ) : (
                  <div className={styles.btn_refuse}>
                    <NavLink
                      to={tryAgainLinkState}>
                      попробовать еще раз
                    </NavLink>
                  </div>
                )}

              {!isQrError ?
                <div className={styles.btn_complete}>
                  <NavLink to={`/${zone}/`}>
                    завершить
                  </NavLink>
                </div>
                :
                null
              }
            </div>
          </div>
        )}
      </SectionContainer>
    </Layout>
  )
}

export default PaymentResultPage;
