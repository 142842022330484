import React, {useEffect} from "react";

export function PageLoading({reload = false}: {reload?: boolean}) {
  useEffect(() => {
    const interval = setTimeout(() => {
      if (reload) window.location.reload();
    }, 1e4);
    return () => {
      clearTimeout(interval);
    };
  }, [reload]);

  return (
    <div className="page-loading">
      <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill="#10C6BE" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite" />
        </path>
      </svg>
    </div>
  );
}
