import { getInfo, InfoType } from "api/query/Info"
import { useEffect, useState } from "react"
import Header from "./header/Header"
import Footer from "./footer/Footer"
import {useParams} from "react-router-dom";

type LayoutType = {
  infoListener?: (info: InfoType) => void;
  children: JSX.Element[] | JSX.Element;
}

const Layout = ({ children, infoListener }: LayoutType) => {
  const { zone } = useParams();
  const [info, setInfo] = useState<InfoType>();

  // const m = window.location.pathname.match(/^\/([^/]+)/);
  // const zone = Array.isArray(m) && m.length >= 2 ? m[1] : '';
  useEffect(() => {
    getInfo(zone).then((data) => {
      setInfo(data);
      if(infoListener) infoListener(data);
    });
  }, [zone, infoListener]);

  /* useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0); //
    }, 10);
  }, []); */

  return (
    <>
      <Header
        address={info?.result.adress}
        tel={info?.result.tel}
      />
      {children}
      <Footer email={info?.result.email} />
    </>
  )
}

export default Layout;

