import {
  getParkingSession, getParkingSessionPhoto, ParkingSessionPhotoType, ParkingSessionResultType, PaymentType,
} from 'api/query/ParkingSession'
import {useEffect, useState} from 'react'
import {NavLink, useParams, useSearchParams} from 'react-router-dom'
import {Row, SectionContainer} from '../ui/grid'
import Title from '../ui/title/Title'
import styles from './SessionConfirmationPage.module.scss'
import Layout from '../layout'
import {LoaderCircle} from "../payment/loader/LoaderCircle";

const SessionConfirmationPage = ({paymentType}: { paymentType: PaymentType }) => {
  const {zone, valueId} = useParams();
  const [searchParams] = useSearchParams();

  const sessionID = searchParams.get("sessionID");
  const [sessionState, setSessionState] = useState<ParkingSessionResultType>();
  const [photo, setPhoto] = useState<ParkingSessionPhotoType>();

  useEffect(() => {
    getParkingSession(zone, paymentType, valueId).then((data) => {
      (data.result || []).forEach((result) => {
        if (result.sessionID === sessionID) {
          setSessionState(result);
        }
      });
    });
  }, [zone, valueId, sessionID, paymentType]);

  useEffect(() => {
    if (sessionID) {
      getParkingSessionPhoto(zone, `${sessionID}`).then((data) => {
        setPhoto(data)
      })
    }
  }, [zone, sessionState, sessionID]);

  let linkNo = '';
  let linkYes = '';
  if (paymentType === PaymentType.grz) {
    linkNo = `/${zone}/grz/${valueId}`;
  } else if (paymentType === PaymentType.phone) {
    linkNo = `/${zone}/phone/${valueId}`;
  }
  if (paymentType === PaymentType.grz) {
    linkYes = `/${zone}/session/${sessionID}/payment`;
  } else if (paymentType === PaymentType.phone) {
    linkYes = `/${zone}/session/${sessionID}/payment`;
  }

  return (
    <Layout>
      <SectionContainer fixHeight>
        <div className={styles.container}>
          <Title text="Это ваш автомобиль?"/>
          {photo ? (
            <>
              <div className={styles.auto}>
                <div className={styles.img_container}>
                  <img src={`data:image/png;base64, ${photo?.result ? photo?.result : ''}`} alt=""/>
                </div>
                <div className={styles.auto_info}>
                  <span>{sessionState?.date.split('T')[0]}</span>
                  <span>{sessionState?.date.split('T')[1]}</span>
                </div>
              </div>
              <div className={styles.btns}>
                <Row spaceBetween nowrap>
                  <NavLink className={styles.btn} to={linkNo}>Нет</NavLink>
                  <NavLink className={styles.btn} to={linkYes}>Да</NavLink>
                </Row>
              </div>
            </>
          ) : <LoaderCircle/>}
        </div>
      </SectionContainer>
    </Layout>
  )
}

export default SessionConfirmationPage
