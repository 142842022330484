import ApiCommon from "api/ApiCommon"

export type InfoType = {
	result: {
		adress: string,
		canPhonePay: boolean,
		canPayAbonement: boolean | null,
		tel: string,
		email: string
	}
}

export function getInfo(zone: string | undefined): Promise<InfoType> {
	return ApiCommon.getLazy("/info", zone)
}
